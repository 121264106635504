import {Dispatch} from "redux";
import axios from "axios";
import environment from "../../environment";
import {
  CLEAR_FILES_MESSAGES,
  DOWNLOAD_FILES_ERROR,
  DOWNLOAD_FILES_SUCCESS,
  postDownloadFileDispatchTypes
} from "../types/downloadFilesTypes";
import {errorHandler} from "../../utils/errorHandler";
import {getToken} from "../../utils/cookies/tokensCookie";
import customHistory from "../../customHistory";
import {getUserCompany} from "../../utils/cookies/userCookies/userCookies";

export const postDownloadFile = (
  limit?:number,
  skip?:number,
  branches?: string[],
  departments?: string[],
  employees?: string[],
  merchants?: string[],
  status?: string,
  startDate?: string,
  endDate?: string,
  exportType?:number
) =>
  async (dispatch: Dispatch<postDownloadFileDispatchTypes>) => {
    const token = getToken();
    const companyId = getUserCompany();
    const companyID = `?companyId=${companyId}`;
    const fullUrl = `${environment.baseUrl}export/generateReportForUser${companyID}`;
    let fileName = "Corplife_invoices.csv";
    let fileType = "application/csv";
    if (exportType && exportType === 1) {
      fileName = "Corplife_invoices.xlsx";
      fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    }
    if (exportType && exportType === 2) {
      fileName = "Corplife_invoices.csv";
      fileType = "application/csv";
    }
    const dynamicParams = {
      limit: limit,
      skip: skip,
    };
    if (branches && branches.length) {
      Object.assign(dynamicParams, {branches: branches});
    }
    if (departments && departments.length) {
      Object.assign(dynamicParams, {departments: departments});
    }
    if (employees && employees.length) {
      Object.assign(dynamicParams, {employees: employees});
    }
    if (merchants && merchants.length) {
      Object.assign(dynamicParams, {merchants: merchants});
    }
    if (status) {
      Object.assign(dynamicParams, {status: status});
    }
    if (startDate && startDate.length) {
      Object.assign(dynamicParams, {startDate: startDate});
    }
    if (endDate && endDate.length) {
      Object.assign(dynamicParams, {endDate: endDate});
    }
    if (exportType) {
      Object.assign(dynamicParams, {exportType: exportType});
    }
    const timeout = setTimeout(() => {
      customHistory.push("/server-error");
    }, 10000);

    try {
      const response = await axios({
        url: fullUrl,
        method: "GET",
        responseType: "blob",
        params: dynamicParams,
        headers: { Authorization: `Bearer ${token}` },
      });
      clearTimeout(timeout);
      window.URL = window.URL || window.webkitURL;

      const xhr = new XMLHttpRequest();
      const a = document.createElement("a");
      let file:any;
      const url = window.URL.createObjectURL(
        new Blob([response.data],
          {type: fileType})
      );
      xhr.open("GET", url, true);
      xhr.responseType = "blob";
      xhr.onload = function () {
        file = new Blob([xhr.response], { type : fileType });
        a.href = window.URL.createObjectURL(file);
        a.download = fileName;
        a.click();
      };
      xhr.send();
      dispatch({
        type: DOWNLOAD_FILES_SUCCESS,
        payload: response,
      });
    } catch (e: any) {
      clearTimeout(timeout);
      if (!errorHandler(e.request.status)) {
        dispatch({
          type: DOWNLOAD_FILES_ERROR,
          payload: "download_invoices",
        });
      }
    }
  };

export function clearDownloadFileMessages() {
  return {
    type: CLEAR_FILES_MESSAGES
  };
}
