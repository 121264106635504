import { Dispatch } from "redux";
import axios from "axios";
import environment from "../../environment";
import {
  ADD_MANY_EMPLOYEE,
  ADD_ONE_EMPLOYEE,
  CLEAR_USER_INFO,
  CLEAR_USERS_MESSAGES,
  DELETE_MANY_USERS,
  DELETE_ONE_USER,
  GET_ONE_USER,
  GET_USER_INFO,
  GET_USERS,
  INACTIVE_USERS,
  INVITE_USER_SUCCESS,
  SHOW_USER_LOADING,
  UPDATE_EMPLOYEE,
  USER_STATISTIC,
  USERS_ERROR,
  usersTypes,
  RESTORE_MANY_USERS,
  CLEAR_USER_INFORMATION
} from "../types/userTypes";
import { errorHandler } from "../../utils/errorHandler";
import {getToken} from "../../utils/cookies/tokensCookie";
import { IUser } from "../../Interfaces/IUser";
import customHistory from "../../customHistory";
import { reduxRequestHandler } from "../../services/reduxRequestHandler";
import { urls } from "../../api/urls";
import {getUserCompany, getUserId} from "utils/cookies/userCookies/userCookies";

interface IGetUsersFilters {
  limit?: number;
  skip?: number;
  branchId?: any[];
  departmentId?: any[];
  search?: string;
  sortBy?: string;
  startDate?: string;
  endDate?: string;
  isActive?: 0 | 1 | undefined;
  employmentType?: string;
  forPlanCreation?: 0 | 1 | undefined;
  [key: string]: any;
}

// Get user info
export const getUserInfo = () => async (dispatch: Dispatch<usersTypes>) => {
  const userId = getUserId();
  if (userId) {
    await new reduxRequestHandler({
      url: `${urls.users.users}/${userId}`,
      successDispatchType: GET_USER_INFO,
      errorDispatchType: USERS_ERROR,
      errorPayload: "get_users"
    }).get(dispatch);
  } else {
    errorHandler(401);
  }
};

// Get Users
export const getUsers =
  (filters: IGetUsersFilters) => async (dispatch: Dispatch<usersTypes>) => {
    const dynamicParams = {
      limit: filters.limit || 8,
      skip: filters.skip || 0
    };
    const companyId = getUserCompany();

    Object.keys(filters).map((key: any) => {
      if (filters[key] || filters[key] === 0) {
        Object.assign(dynamicParams, { [key]: filters[key] });
      }
    });
    await new reduxRequestHandler({
      url: `${urls.users.users}?companyId=${companyId}`,
      dynamicParams,
      successDispatchType: GET_USERS,
      errorDispatchType: USERS_ERROR,
      errorPayload: "get_users"
    }).get(dispatch);
  };

// Get one user
export const getOneUser =
  (userId: string) => async (dispatch: Dispatch<usersTypes>) => {
    await new reduxRequestHandler({
      url: `${urls.users.users}/${userId}`,
      successDispatchType: GET_ONE_USER,
      errorDispatchType: USERS_ERROR,
      errorPayload: "get_one_user"
    }).get(dispatch);
  };

// Get user statistic
export const getUserStatistic =
  (userId: string, startDate: string, endDate: string) =>
    async (dispatch: Dispatch<usersTypes>) => {
      const companyId = getUserCompany();
      await new reduxRequestHandler({
        url: `${urls.invoices.indexByMultipleDynamicSearchByUser}?companyId=${companyId}`,
        successDispatchType: USER_STATISTIC,
        errorDispatchType: USERS_ERROR,
        errorPayload: "user_statistic"
      }).get(dispatch);
    };

// Get users without app
export const getInactiveUsers =
  (limit?: number) => async (dispatch: Dispatch<usersTypes>) => {
    const companyId = getUserCompany();
    const dynamicParams = {
      limit: limit
    };
    await new reduxRequestHandler({
      url: `${urls.users.reportUser}?companyId=${companyId}`,
      dynamicParams,
      successDispatchType: INACTIVE_USERS,
      errorDispatchType: USERS_ERROR,
      errorPayload: "inactive_users"
    }).get(dispatch);
  };

// Invite users
export const inviteUser =
  (email: any) => async (dispatch: Dispatch<usersTypes>) => {
    const token = getToken();
    const fullUrl = `${environment.baseUrl}${urls.general.inviteUser}`;
    const options = { headers: { Authorization: `Bearer ${token}` } };
    const timeout = setTimeout(() => {
      customHistory.push("/server-error");
    }, 10000);
    try {
      await axios.post(fullUrl, email, options).then(
        (response) => {
          clearTimeout(timeout);
          dispatch({
            type: INVITE_USER_SUCCESS,
            payload: "invite_users"
          });
        },
        (error) => {
          dispatch({
            type: USERS_ERROR,
            payload: "invite_users"
          });
        }
      );
    } catch (e: any) {
      clearTimeout(timeout);
      if (!errorHandler(e.request.status)) {
        dispatch({
          type: USERS_ERROR,
          payload: "invite_users"
        });
      }
    }
  };

// Add many employee
export const postManyEmployee =
  (data: { companyId: string; users: IUser[] }) =>
    async (dispatch: Dispatch<usersTypes>) => {
      const token = getToken();
      const fullUrl = `${environment.baseUrl}${urls.users.addMany}`;
      const options = { headers: { Authorization: `Bearer ${token}` } };
      const sentLength = data.users.length;
      try {
        const response = await axios.post(fullUrl, data, options);
        if (response.data.existUsers.length === sentLength) {
          dispatch({
            type: USERS_ERROR,
            payload: "user_exist"
          });
        } else {
          dispatch({
            type: ADD_MANY_EMPLOYEE,
            payload: "add_one_employee"
          });
        }
      } catch (e: any) {
        if (!errorHandler(e.request.status)) {
          dispatch({
            type: USERS_ERROR,
            payload: "add_many_employee"
          });
        }
      }
    };

// Add one employee
export const addOneEmployee =
  (data: IUser) => async (dispatch: Dispatch<usersTypes>) => {
    const token = getToken();
    const fullUrl = `${environment.baseUrl}${urls.users.users}`;
    const options = { headers: { Authorization: `Bearer ${token}` } };
    const timeout = setTimeout(() => {
      customHistory.push("/server-error");
    }, 10000);
    try {
      await axios.post(fullUrl, data, options);
      clearTimeout(timeout);
      dispatch({
        type: ADD_ONE_EMPLOYEE,
        payload: "add_one_employee"
      });
    } catch (e: any) {
      clearTimeout(timeout);
      if (!errorHandler(e.request.status)) {
        dispatch({
          type: USERS_ERROR,
          payload: "user_exist"
        });
      }
    }
  };

// Update user
export const updateEmployee =
  (data: IUser, userId?: string) => async (dispatch: Dispatch<usersTypes>) => {
    const token = getToken();
    let user;
    if (userId) {
      user = userId;
    } else {
      user = getUserId();
    }
    const fullUrl = `${environment.baseUrl}${urls.users.users}/${user}`;
    const options = { headers: { Authorization: `Bearer ${token}` } };
    const timeout = setTimeout(() => {
      customHistory.push("/server-error");
    }, 10000);
    try {
      await axios.put(fullUrl, data, options);
      clearTimeout(timeout);
      dispatch({
        type: UPDATE_EMPLOYEE,
        payload: "update_employee"
      });
    } catch (e: any) {
      clearTimeout(timeout);
      if (!errorHandler(e.request.status)) {
        dispatch({
          type: USERS_ERROR,
          payload:
            e.response.data.message === "This email already registered"
              ? "This email already registered"
              : "update_employee"
        });
      }
    }
  };

// Delete one user-
export const deleteOneUser =
  (data: string) => async (dispatch: Dispatch<usersTypes>) => {
    const token = getToken();
    const weavrToken = getToken(true);
    const fullUrl = `${environment.baseUrl}${urls.users.users}/${data}`;
    const options = { headers: { Authorization: `Bearer ${token}` }, params: {weavrToken} };
    const timeout = setTimeout(() => {
      customHistory.push("/server-error");
    }, 10000);
    try {
      await axios.delete(fullUrl, options);
      clearTimeout(timeout);
      dispatch({
        type: DELETE_ONE_USER,
        payload: "delete_user"
      });
    } catch (e: any) {
      clearTimeout(timeout);
      if (!errorHandler(e.request.status)) {
        dispatch({
          type: USERS_ERROR,
          payload:
            e.response.data.message === "Company need at least 1 Admin"
              ? "delete_user_admin"
              : "delete_user"
        });
      }
    }
  };

// Delete many users
export const deleteUsers =
  (data: string[]) => async (dispatch: Dispatch<usersTypes>) => {
    const token = getToken();
    const weavrToken = getToken(true);
    const fullUrl = `${environment.baseUrl}${urls.users.deleteMany}`;
    const options = { headers: { Authorization: `Bearer ${token}` }, params: {weavrToken: weavrToken} };
    const timeout = setTimeout(() => {
      customHistory.push("/server-error");
    }, 10000);
    try {
      await axios.post(fullUrl, data, options);
      clearTimeout(timeout);
      dispatch({
        type: DELETE_MANY_USERS,
        payload: "delete_user"
      });
    } catch (e: any) {
      clearTimeout(timeout);
      if (!errorHandler(e.request.status)) {
        dispatch({
          type: USERS_ERROR,
          payload: "delete_user"
        });
      }
    }
  };

// Delete many users
export const restoreUsers =
  (data: string[]) => async (dispatch: Dispatch<usersTypes>) => {
    const token = getToken();
    const fullUrl = `${environment.baseUrl}${urls.users.restoreMany}`;
    const options = { headers: { Authorization: `Bearer ${token}` } };
    const timeout = setTimeout(() => {
      customHistory.push("/server-error");
    }, 10000);

    try {
      await axios.post(fullUrl, data, options);
      clearTimeout(timeout);
      dispatch({
        type: RESTORE_MANY_USERS,
        payload: "restore_users"
      });
    } catch (e: any) {
      clearTimeout(timeout);
      if (!errorHandler(e.request.status)) {
        dispatch({
          type: USERS_ERROR,
          payload: "delete_user"
        });
      }
    }
  };

// Show loading
export function showUserLoading() {
  return {
    type: SHOW_USER_LOADING
  };
}

// Clear messages
export function clearUserMessages() {
  return {
    type: CLEAR_USERS_MESSAGES
  };
}

// Clear user info
export function cleanupUserInformation() {
  return {
    type: CLEAR_USER_INFO
  };
}

interface hrZoneUsersFilters {
	limit?: number;
  skip?: number;
  search?: string;
  sortBy?: string;
  isActive?: 0 | 1 | undefined;
  [key: string]: any;
	sessionKey?: string
}
//Get Hrzone Users
export const getHrZoneUsers =
  (filters: hrZoneUsersFilters) => async (dispatch: Dispatch<usersTypes>) => {
    const dynamicParams = {
      perPage: filters.perPage || 8,
      page: filters.page || 1
    };

    Object.keys(filters).map((key: any) => {
      if (filters[key] || filters[key] === 0) {
        Object.assign(dynamicParams, { [key]: filters[key] });
      }
    });
    await new reduxRequestHandler({
      url: urls.hrZone.base + "getEmployees",
      dynamicParams,
      successDispatchType: GET_USERS,
      errorDispatchType: USERS_ERROR,
      errorPayload: "get_users"
    }).postHrZone(dispatch);
  };

export const getHrZoneHrUsers =
  (filters: hrZoneUsersFilters) => async (dispatch: Dispatch<usersTypes>) => {
    const dynamicParams = {
      perPage: filters.perPage || 8,
      page: filters.page || 1
    };

    Object.keys(filters).map((key: any) => {
      if (filters[key] || filters[key] === 0) {
        Object.assign(dynamicParams, { [key]: filters[key] });
      }
    });
    await new reduxRequestHandler({
      url: urls.hrZone.base + "gethremployees",
      dynamicParams,
      successDispatchType: GET_USERS,
      errorDispatchType: USERS_ERROR,
      errorPayload: "get_users"
    }).postHrZone(dispatch);
  };

// Get Users
export const getUsersForCardCreation =
  (filters: IGetUsersFilters) => async (dispatch: Dispatch<usersTypes>) => {
    const dynamicParams = {
      limit: filters.limit || 8,
      skip: filters.skip || 0,
	  sessionKey: filters.hrZoneSessionKey
    };
    const companyId = getUserCompany();

    Object.keys(filters).map((key: any) => {
      if (filters[key] || filters[key] === 0) {
        Object.assign(dynamicParams, { [key]: filters[key] });
      }
    });
    await new reduxRequestHandler({
      url: "payments/getUsersForCardCreation/" + companyId,
      dynamicParams,
      successDispatchType: GET_USERS,
      errorDispatchType: USERS_ERROR,
      errorPayload: "get_users"
    }).get(dispatch);
  };



export function clearUserInfo() {
  return {
	  type: CLEAR_USER_INFORMATION
  };
}
