import {Dispatch} from "redux";
import axios from "axios";
import environment from "../../environment";
import {tracker} from "../../App";
import customHistory from "../../customHistory";
import {
  AUTH_ERROR,
  authDispatchTypes,
  CHANGE_PASSWORD,
  CLEAR_AUTH_MESSAGES,
  LOGIN_SUCCESS,
  LOGOUT, RESET_PASSWORD,
  USER_TOKEN
} from "../types/authTypes";
import {errorHandler} from "../../utils/errorHandler";
import {removeToken, setToken} from "../../utils/cookies/tokensCookie";
import {
  setUserPermission,
  setUserPermissionType,
  setUserRoles
} from "../../utils/cookies/userRolesCookies";
import {urls} from "../../api/urls";
import {setMagentoData, setUserCompany, setUserId} from "../../utils/cookies/userCookies/userCookies";
import { magentoData } from "pages/dashboard/route";
import { string } from "prop-types";
import { CLEAR_COMPANY } from "redux/types/companiesTypes";

interface Loginuser {
	email: string,
	password?: string,
	token?: string
}

// Login
export const loginUser =
	(options: Loginuser)  =>
	  async (dispatch: Dispatch<authDispatchTypes>) => {
	    const timeout = setTimeout(() => {
	      customHistory.push("/server-error");
	    }, 10000);
	    try {
	      let role = 0;
	      const fullUrl = `${environment.baseUrl}${urls.auth.magentoLogin}`;
	      await axios.post(fullUrl, {
	        ...options,
	        deviceName: "web"
	      }).then(function (response) {
	        clearTimeout(timeout);
	        if (response.data.user.roleType) {
	          role = response.data.user.roleType;
	          setUserRoles(role);
	        } else {
	          role = 4;
	          setUserRoles(role);
	        }
	        if (response.data.user.roleType === 3) {
	          let permissionsList = "";
	          response.data.user.permissions
	            .forEach((permission: any) => {
	              if (permission.isActive || permission.isAdmin) {
	                permissionsList += permission.type.toString();
	              }
	            });
	          setUserPermissionType(permissionsList.toString());
	          setUserPermission(response.data.user.permissions);
	        }
	        tracker && tracker.setUserID(response.data.user.email);
	        setToken(response.data.token.token);
	        //normal portal user
	        if (response.data.magentoData) {
	          setMagentoData(response.data.magentoData);
	        }

	        if(response.data.token.userId){
	          const id = response.data.token.userId;
	          setUserId(id);
	          const companyId = response.data.user.companyId;
	          setUserCompany(companyId);
	        }else{
	          //new user coming from magento which is not existing on portal yet
	          let magentoUserId = "magento_user "+options.email + " ";
	          if (process.env.REACT_APP_ENV !== "production") {
	            magentoUserId += (response.data.magentoData.partnerPortal && "partnerPortal");
	          }
	          setUserId(magentoUserId);
	          setUserCompany("magento_company");
	        }
	      });
	      dispatch({
	        type: LOGIN_SUCCESS
	      });
	    } catch (e: any) {
	      console.error(e);
	      clearTimeout(timeout);
	      dispatch({
	        type: AUTH_ERROR,
	        payload: "login"
	      });
	    }
	  };


// Change password
export const changePassword = (data:{email: string, password: string}) =>
  async (dispatch: Dispatch<authDispatchTypes>) => {
    const fullUrl = `${environment.baseUrl}${urls.auth.resetPassword}`;
    const timeout = setTimeout(() => {
      customHistory.push("/server-error");
    }, 10000);
    try {
      await axios.post(fullUrl, data);
      clearTimeout(timeout);
      dispatch({
        type: CHANGE_PASSWORD,
        payload: "change_password"
      });
    } catch (e: any) {
      clearTimeout(timeout);
      if (!errorHandler(e.request.status)) {
        dispatch({
          type: AUTH_ERROR,
          payload: "change_password"
        });
      }
    }
  };



// Change password from email
export const changePasswordFromEmail = (data: {email: string, password: string}, code:string) =>
  async (dispatch: Dispatch<authDispatchTypes>) => {
    const timeout = setTimeout(() => {
      customHistory.push("/server-error");
    }, 10000);
    try {
      const response = await axios.post(`${environment.baseUrl}${urls.auth.codeValidation}`, {
        code: code,
        email: data.email
      });
      clearTimeout(timeout);
      if (response.data.validated) {
        const fullUrl = `${environment.baseUrl}${urls.auth.resetPassword}`;
        const activateUserUrl = `${environment.baseUrl}${urls.general.activateUser}`;
        try {
          await axios.post(fullUrl, data);
          await axios.post(activateUserUrl, data);
          clearTimeout(timeout);
          dispatch({
            type: CHANGE_PASSWORD,
            payload: "change_password"
          });
        } catch (e: any) {
          clearTimeout(timeout);
          if (!errorHandler(e.request.status)) {
            dispatch({
              type: AUTH_ERROR,
              payload: "change_password"
            });
          }
        }
      } else {
        clearTimeout(timeout);
        dispatch({
          type: AUTH_ERROR,
          payload: "wrong_verification"
        });
      }
    } catch (e: any) {
      clearTimeout(timeout);
      if (!errorHandler(e.request.status)) {
        dispatch({
          type: AUTH_ERROR,
          payload: "wrong_verification"
        });
      }
    }
  };

// Reset password
export const resetPassword = (data: {email:string, domain:string}) =>
  async (dispatch: Dispatch<authDispatchTypes>) => {
    const fullUrl = `${environment.baseUrl}${urls.general.resetEmail}`;
    const timeout = setTimeout(() => {
      customHistory.push("/server-error");
    }, 10000);
    try {
      await axios.post(fullUrl , data);
      clearTimeout(timeout);
      dispatch({
        type: RESET_PASSWORD,
        payload: "reset_password"
      });
    } catch (e: any) {
      clearTimeout(timeout);
      if (!errorHandler(e.request.status)) {
        dispatch({
          type: AUTH_ERROR,
          payload: "reset_password"
        });
      }
    }
  };

// Get user token
export const userTokenDispatcher =
	(token: string) => (dispatch: Dispatch) => {
	  dispatch({
	    type: USER_TOKEN,
	    payload: token
	  });
	};

// Logout user
export const logoutDispatcher = () => (dispatch: Dispatch) => {
  removeToken();

  dispatch({
    type: LOGOUT
  });
};

// Clear messages
export function clearAuthMessages() {
  return {
    type: CLEAR_AUTH_MESSAGES
  };
}
