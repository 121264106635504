import {Dispatch} from "redux";
import axios from "axios";
import environment from "../../environment";
import {
  CLEAR_MARKETING_MESSAGES,
  POST_MARKETING_ERROR,
  POST_MARKETING_SUCCESS,
  postMarketingDispatchTypes
} from "../types/postMarketing";
import {errorHandler} from "../../utils/errorHandler";
import {getToken} from "../../utils/cookies/tokensCookie";
import customHistory from "../../customHistory";


export const postMarketing = (data:any) =>
  async (dispatch: Dispatch<postMarketingDispatchTypes>) => {
    const token = getToken();
    const fullUrl = `${environment.baseUrl}marketing`;
    const options = {headers: {"Authorization": `Bearer ${token}`}};
    const timeout = setTimeout(() => {
      customHistory.push("/server-error");
    }, 10000);
    try {
      await axios.post(fullUrl , data, options);
      clearTimeout(timeout);
      dispatch({
        type: POST_MARKETING_SUCCESS
      });
    } catch (e:any) {
      clearTimeout(timeout);
      if (e.response.status === 422) {
        dispatch({
          type: POST_MARKETING_ERROR,
          payload: "2"
        });
      } else if (!errorHandler(e.request.status)) {
        dispatch({
          type: POST_MARKETING_ERROR,
          payload: "1"
        });
      }
    }
  };

export function clearMarketingMessages() {
  return {
    type: CLEAR_MARKETING_MESSAGES
  };
}
