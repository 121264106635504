import { Dispatch } from "redux";
import environment from "../../environment";
import axios from "axios";
import {
  CLEAR_COMPANY_MESSAGES,
  COMPANIES_INFORMATION_SUCCESS,
  companiesDataDispatchTypes,
  COMPANY_DATA_ERROR,
  COMPANY_INFORMATION_SUCCESS,
  UPDATE_COMPANY_INFORMATION,
  CLEAR_COMPANY
} from "../types/companiesTypes";
import { errorHandler } from "../../utils/errorHandler";
import { getToken } from "../../utils/cookies/tokensCookie";
import { ICompanyUpdate } from "../../Interfaces/ICompany";
import customHistory from "../../customHistory";
import { reduxRequestHandler } from "../../services/reduxRequestHandler";
import { urls } from "../../api/urls";
import {getUserCompany} from "../../utils/cookies/userCookies/userCookies";

// Get company data
export const getCompanyData =
  (id?: string) => async (dispatch: Dispatch<companiesDataDispatchTypes>) => {
    const companyId = id ? id : getUserCompany();
    await new reduxRequestHandler({
      url: `${urls.companies.companies}/${companyId}`,
      successDispatchType: COMPANY_INFORMATION_SUCCESS,
      errorDispatchType: COMPANY_DATA_ERROR,
      errorPayload: "get_company"
    }).get(dispatch);
  };

// Get companies
export const getCompanies =
  (limit?: number, skip?: number) =>
    async (dispatch: Dispatch<companiesDataDispatchTypes>) => {
      const dynamicParams = {
        limit: limit,
        skip: skip
      };
      await new reduxRequestHandler({
        url: urls.companies.companies,
        dynamicParams,
        successDispatchType: COMPANIES_INFORMATION_SUCCESS,
        errorDispatchType: COMPANY_DATA_ERROR,
        errorPayload: "companies_error"
      }).get(dispatch);
    };

// Update company
export const updateCompany =
  (data: ICompanyUpdate) =>
    async (dispatch: Dispatch<companiesDataDispatchTypes>) => {
      const token = getToken();
      const companyId = getUserCompany();
      const fullUrl = `${environment.baseUrl}${urls.companies.companyUpdate}/${companyId}`;
      const options = { headers: { Authorization: `Bearer ${token}` } };
      const timeout = setTimeout(() => {
        customHistory.push("/server-error");
      }, 10000);
      try {
        await axios.put(fullUrl, data, options);
        clearTimeout(timeout);
        dispatch({
          type: UPDATE_COMPANY_INFORMATION,
          payload: "update_company"
        });
      } catch (e: any) {
        clearTimeout(timeout);
        if (!errorHandler(e.request.status)) {
          dispatch({
            type: COMPANY_DATA_ERROR,
            payload: "update_company"
          });
        }
      }
    };

export function clearCompanyMessages() {
  return {
    type: CLEAR_COMPANY_MESSAGES
  };
}
export function clearCompany() {
  return {
    type: CLEAR_COMPANY
  };
}
