import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { connect, useDispatch } from "react-redux";
import { RootState } from "../../redux/reducers/rootReducer";
import useWindowDimensions from "../../utils/useWindowSize";
import moment from "moment-business-days";
import { handleChangePlan } from "../../redux/actions/addedPlansActions";
import axios from "axios";
import environment from "../../environment";
import { useTranslation } from "react-i18next";
import { getToken } from "../../utils/cookies/tokensCookie";

import "./LunchForm.css";
import employeeIcon from "img/Dashboard/PlansAndModules/employee_icon.svg";
import deletePlanIcon from "img/Dashboard/PlansAndModules/Icon_delete_plan.svg";

import Spacer from "../SimpleComponents/Spacer";
import VerticalDivider from "./VerticalDivider";

import Modal from "react-modal";
import EmployeePlansModal from "../Modals/EmployeePlansModal";

import DailyLimitContainer from "./DailyLimitContainer";
import CustomToastContainer from "../SimpleComponents/ToastContainer";
import SimpleInput from "../SimpleComponents/SimpleInput";
import { ILunchFormPlan } from "../../Interfaces/IPlans";
import { modalStyles } from "../../utils/modalStyles";
import { urls } from "../../api/urls";
import Button from "Components/Buttons/Button";
import {getUserCompany} from "../../utils/cookies/userCookies/userCookies";
import Switch from "../Elements/Switch/Switch";

interface CustomProps {
  handleSum: (sum: number) => void;
  handleData: (data: any) => void;
  selectedRows?: any;
  updatePlanData: any;
  isCopyPlan?: boolean;
  addedPlans?: any;
  supermarketsAllowed: boolean;
  toggleSupermarketAllowed: () => void;
  setIsFetched: (value: boolean) => void
  company?: any;
}

function LunchForm({
  handleSum,
  updatePlanData,
  isCopyPlan,
  addedPlans,
  supermarketsAllowed,
  toggleSupermarketAllowed,
  setIsFetched,
  company
}: CustomProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [storedDates, setStoredDates] = useState<any>(null);
  // Set if in update plan are already invoices
  const [invoicesExists, setInvoicesExists] = useState(false);
  // Modal with employee
  const [showModal, setShowModal] = useState(false);

  // Plans state
  const [plansArray, setPlansArray] = useState<ILunchFormPlan[]>([]);

  // Handle popup with employee
  const handleModal = (state: boolean, data: any) => {
    const updatedPlansArray = plansArray.map((item: any, i: number) =>
      i === currentPlan || isCopyPlan ? { ...item, employee: data } : item
    );
    setPlansArray(updatedPlansArray);
    setShowModal(state);
    setCurrentPlan(undefined);
    setEmployeeInPlan([]);
  };

  // initial state of checkboxes for limit
  const [checkBoxChecked, setCheckBoxChecked] = useState(true);

  // Add new plan to array and close inputs and calendar
  const addNewPlan = (plan: any) => {
    if (updatePlanData && Object.keys(updatePlanData).length) {
      if (isCopyPlan) {
        plan.title = `${plan.title} Kopie`;
        // Find if there are any same dates between old and new plan => clear employee array
        let clearEmployeeState = false;
        const storedStartDate = moment(
          storedDates.startDate,
          "DD.MM.YYYY"
        ).valueOf();
        const storedEndDate = moment(
          storedDates.endDate,
          "DD.MM.YYYY"
        ).valueOf();
        const newStartDate = moment(
          plan.dateRange.startDate,
          "DD.MM.YYYY"
        ).valueOf();
        const newEndDate = moment(
          plan.dateRange.endDate,
          "DD.MM.YYYY"
        ).valueOf();
        if (storedStartDate === newStartDate) {
          clearEmployeeState = true;
        } else if (newEndDate === storedEndDate) {
          clearEmployeeState = true;
        } else if (
          storedStartDate > newStartDate &&
          storedStartDate < newEndDate
        ) {
          clearEmployeeState = true;
        } else if (
          storedStartDate < newStartDate &&
          storedEndDate > newEndDate
        ) {
          clearEmployeeState = true;
        } else if (storedEndDate > newStartDate && storedEndDate < newEndDate) {
          clearEmployeeState = true;
        } else {
          clearEmployeeState = false;
        }
        if (clearEmployeeState) {
          plan.employee = [];
        } else {
          plan.employee = plansArray[0].employee;
        }
      }
      setPlansArray([plan]);
    } else {
      setPlansArray([...plansArray, plan]);
    }
    setCheckBoxChecked(false);
  };

  // Return largest limit (restaurant or supermarket)
  const returnLargerLimit = (resLimit: string, superLimit: string) => {
    return +resLimit > +superLimit ? resLimit : superLimit;
  };

  // Transform dates for input
  const transformInputDates = (startDate: string, endDate: string) => {
    return `${startDate} - ${endDate}`;
  };

  // Need it for autofocus in input
  const [selectedInput, setSelectedInput] = useState("");
  // Set title values for plan
  const handlePlanChangeTitle = (value: string, index: number) => {
    setSelectedInput(index.toString());
    setPlansArray(
      plansArray.map((item: any, i: number) =>
        i === index ? { ...item, title: value } : item
      )
    );
  };

  // Delete plan from list
  const removePlan = (index: number) => {
    setPlansArray(plansArray.filter((item: any, i: number) => i !== index));
  };

  // Handle open employee modal to set index of plan
  const [employeeInPlan, setEmployeeInPlan] = useState<any>([]);
  const [currentPlan, setCurrentPlan] = useState<number>();
  const openEmployeeModal = (index: number) => {
    if (isCopyPlan && !fetchedCopyData) {
      //disable button if not all data was fetched for copy plan
    } else {
      setCurrentPlan(index);
      setShowModal(true);
      let employyesToSelect:string[] = plansArray[index].employee;
      if (Object.keys(updatePlanData).length) {
        const transformedUsersArray = plansArray[index].employee.map((user: any) => user._id);
        employyesToSelect = transformedUsersArray;
      }
      setEmployeeInPlan(employyesToSelect);
    }
  };

  // set responsive ratio for container
  const [ratio, setRatio] = useState("30% 70%");

  // set less item in table for small screens
  const { width } = useWindowDimensions();
  useEffect(() => {
    if (width < 600) {
      setRatio("100%");
    } else if (width > 600) {
      setRatio("30% 70%");
    }
  }, [width]);

  const [fetchedCopyPlan, setFetchedCopyPlan] = useState(false);

  // Handle sum of plan
  const takeSumFromServer = async (postArray?: any) => {
    try {
      const response = await axios.post(
        `${environment.baseUrl}${urls.plans.calculate}`,
        postArray,
        {
          headers: { Authorization: `Bearer ${getToken()}` }
        }
      );
      const sum = response.data.totalSummary;
      if (sum) {
        handleSum(+sum.toFixed(2));
      } else {
        handleSum(0);
      }
    } catch (e: any) {
      toast.error(
        <CustomToastContainer
          message={t("warnings.send_invite_error")}
          status={3}
        />,
        {
          autoClose: 5000
        }
      );
    }
  };

  const [fetchedCopyData, setFetchedCopyData] = useState(false);
  useEffect(() => {
    if (isCopyPlan && !fetchedCopyPlan && plansArray[0]?.dateRange?.key) {
      const fetch = async () => {
        try {
          const response = await axios.get(
            `${environment.baseUrl}${
              urls.users.users
            }?companyId=${getUserCompany()}&limit=0&skip=0&startDate=${moment(
              plansArray[0].dateRange.startDate,
              "DD.MM.YYYY"
            ).format("YYYY-MM-DD")}&endDate=${moment(
              plansArray[0].dateRange.endDate,
              "DD.MM.YYYY"
            ).format("YYYY-MM-DD")}&isActive=1&forPlanCreation=1`,
            {
              headers: { Authorization: `Bearer ${getToken()}` }
            }
          );

          if (response.status === 200) {
            setFetchedCopyPlan(true);
            if (
              updatePlanData.plan.employees.length > 0 &&
              response.data.users &&
              response.data.users.length > 0
            ) {
              const tempArray = updatePlanData.plan.employees
                .map((employee: any) => {
                  return response.data.users.find(
                    (user: any) => user._id === employee
                  );
                })
                .filter((element: string) => {
                  return element !== undefined;
                });

              const notAvailableUsers: any[] = [];
              updatePlanData.plan.employees.forEach((employee: any) => {
                const user = tempArray.find(
                  (user: any) => user._id === employee
                );
                if (!user) {
                  notAvailableUsers.push(employee);
                }
              });

              if (notAvailableUsers.length > 0) {
                //some users are already in plans and not available for copyPlan
                toast.error(
                  <CustomToastContainer
                    message={
                      notAvailableUsers.length + " users are not available"
                    }
                    status={3}
                  />,
                  {
                    autoClose: 5000
                  }
                );
              }

              //   plansArray[0].employee =
              setPlansArray(
                plansArray.map((item: any, i: number) => {
                  return {
                    ...item,
                    employee: tempArray
                  };
                })
              );

              dispatch(handleChangePlan(plansArray));
            }
          } else {
            toast.error(
              <CustomToastContainer
                message={t("warnings.update_company_info_error")}
                status={3}
              />,
              {
                autoClose: 5000
              }
            );
          }
          setFetchedCopyData(true);


          setIsFetched(true);


        } catch (e) {
          toast.error(
            <CustomToastContainer
              message={t("warnings.update_company_info_error")}
              status={3}
            />,
            {
              autoClose: 5000
            }
          );
        }
      };
      fetch();
    }
  }, [plansArray[0]?.dateRange]);

  //   const [initializedCopyPlan, setInitializedCopyPlan] = useState(false)
  // Get summary calculation after plans updates
  useEffect(() => {
    if (
      !storedDates &&
      plansArray[0]?.dateRange?.startDate &&
      plansArray[0]?.dateRange?.endDate
    ) {
      setStoredDates({
        startDate: plansArray[0].dateRange.startDate,
        endDate: plansArray[0].dateRange.endDate
      });
    }

    // if (isCopyPlan) {
    //   plansArray[0].employee = updatePlanData.plan.employees;
    // }

    dispatch(handleChangePlan(plansArray));
    if (plansArray.length) {
      const postArray: any = [];
      let indexCounter = 0;
      const temporaryArray = [...plansArray];
      temporaryArray.map((plan: any) => {
        const startDate = `${plan.dateRange.startDate
          .split(".")
          .reverse()
          .join("-")} 00:00:00.000`;
        const endDate = `${plan.dateRange.endDate
          .split(".")
          .reverse()
          .join("-")} 00:00:00.000`;
        indexCounter += 1;
        postArray.push({
          index: indexCounter,
          startDate: startDate,
          endDate: endDate,
          type: Number(plan.generalLimit) > 0 ? "2" : "1",
          totalAmount: Number(plan.generalLimit) > 0
            ? plan.limit
            : returnLargerLimit(plan.restaurantLimit, plan.supermarketLimit),
          employees: plan.employee,
          generalLimit: Number(plan.generalLimit) > 0 ? Number(plan.generalLimit) : 0,
          supermarket: plan.supermarketLimit ? plan.supermarketLimit : 0,
          restaurant: plan.restaurantLimit ? plan.restaurantLimit : 0,
          selectedDays: plan.selectedDays,
          allowWorkOnHolidays: plan.allowWorkOnHolidays,
          country: company.country
        });
      });

	  takeSumFromServer(postArray);

    } else {
      handleSum(0);
    }
  }, [plansArray]);

  // If update plans page
  useEffect(() => {
    // Set to show warning modal
    if (
      updatePlanData.invoiceCount &&
      updatePlanData.invoiceCount > 0 &&
      !isCopyPlan
    ) {
      setInvoicesExists(true);
    }
    if (updatePlanData && Object.keys(updatePlanData).length) {
      setCheckBoxChecked(true);
      const startDate = moment(updatePlanData.plan.startDate).format(
        "DD.MM.YYYY"
      );
      const endDate = moment(updatePlanData.plan.endDate).format("DD.MM.YYYY");
      const newArr: any = [];
      updatePlanData.plan.employees.forEach((employee: string) => {
        newArr.push({
          _id: employee
        });
      });
      const dailyArray = {
        dateRange: {
          startDate: startDate,
          endDate: endDate
        },
        employee: newArr,
        restaurantLimit: updatePlanData.plan.restaurant,
        supermarketLimit: updatePlanData.plan.supermarket,
        title: isCopyPlan
          ? `${updatePlanData.plan.title} Copy`
          : updatePlanData.plan.title,
        selectedDays: updatePlanData.plan.selectedDays,
        allowWorkOnHolidays: updatePlanData.plan.allowWorkOnHolidays,
        isSupermarketAllowed: !updatePlanData.plan.isSupermarketDisabled
      };
      const generalArray = {
        dateRange: {
          startDate: startDate,
          endDate: endDate
        },
        limit: updatePlanData.plan.generalLimit,
        employee: newArr,
        title: updatePlanData.plan.title,
        selectedDays: updatePlanData.plan.selectedDays,
        allowWorkOnHolidays: updatePlanData.plan.allowWorkOnHolidays
      };
      setPlansArray(
        +updatePlanData.plan.generalLimit > 0 ? [generalArray] : [dailyArray]
      );
    }
  }, [updatePlanData]);

  const daysOptions: any = [
    { value: 1, label: t("dashboard.plans.days.0") },
    { value: 2, label: t("dashboard.plans.days.1") },
    { value: 3, label: t("dashboard.plans.days.2") },
    { value: 4, label: t("dashboard.plans.days.3") },
    { value: 5, label: t("dashboard.plans.days.4") },
    { value: 6, label: t("dashboard.plans.days.5") },
    { value: 0, label: t("dashboard.plans.days.6") }
  ];

  useEffect(() => {
    if (isCopyPlan) {
      setInvoicesExists(false);
      setCheckBoxChecked(true);
    }
  }, [isCopyPlan]);

  const isValidTimePeriod = () => {
    if (isCopyPlan && addedPlans && addedPlans.length > 0 && updatePlanData.plan) {
      const newPlanStartDate = moment(
        addedPlans[0].dateRange.startDate,
        "DD.MM.YYYY"
      ).valueOf();
      const newPlanEndDate = moment(
        addedPlans[0].dateRange.endDate,
        "DD.MM.YYYY"
      ).valueOf();
      const oldPlanStartDate = moment(updatePlanData.plan.startDate).valueOf();
      const oldPlanEndDate = moment(updatePlanData.plan.endDate).valueOf();

      if (
        newPlanStartDate > oldPlanEndDate ||
        newPlanEndDate < oldPlanStartDate
      ) {
        return true;
      }
    }

    return false;
  };

  return (
    <div className="plans_container_main" style={{ padding: "50px 20px" }}>
      {invoicesExists ? null : (
        <>
          <div className="lunch_underlined">
            <div>
              <h2 className="plans_form_title">
                {t("dashboard.plans.lunch_form_opt_1")}
              </h2>
              <p>{t("dashboard.plans.lunch_form_opt_1_text")}</p>
            </div>

            {!plansArray[0]?.limit
              &&
              <Switch
                checked={supermarketsAllowed}
                onChange={() => toggleSupermarketAllowed()}
                id={"radio"}
                label={t("dashboard.plans.create_page.supermarkets_switch")}
                disabled={updatePlanData
                  && Object.keys(updatePlanData).length > 0
                  && moment(updatePlanData?.plan?.startDate).startOf("day").toDate() <= moment().toDate()}
              />
            }

          </div>
          <VerticalDivider ratio={ratio}>
            <div />

            <div style={{ position: "relative" }}>
              {checkBoxChecked ? (
                <>
                  <DailyLimitContainer
                    updatePlanData={updatePlanData}
                    addNewPlan={addNewPlan}
                    plansArray={plansArray}
                    daysOptions={daysOptions}
                    isCopyPlan={isCopyPlan}
                    addedPlans={addedPlans}
                    supermarketsAllowed={supermarketsAllowed}
                  />
                </>
              ) : null}
            </div>
          </VerticalDivider>
        </>
      )}

      <Spacer spacerHeight="50px" />
      <div className="row">
        <h2 className="plans_form_title">
          {t("dashboard.plans.lunch_form_opt_2")}
        </h2>
        <div className="plans_form_line" />
      </div>
      {/* List of added plans */}
      {isCopyPlan && !isValidTimePeriod() ? (
        <></>
      ) : (
        <div className="plans_item_container">
          {plansArray.map((item: any, i: number) => (
            <div className="plans_item_box" key={i.toString()}>
              <div style={{ width: "80px", marginTop: "17px" }}>
                {Number(item.generalLimit) > 0 ? (
                  <p className="plan_name">
                    {t("dashboard.plans.name_general")}
                  </p>
                ) : (
                  <p className="plan_name">{t("dashboard.plans.name_daily")}</p>
                )}
              </div>
              <SimpleInput
                style={{ minWidth: "200px", marginRight: "10px" }}
                id={"title"}
                name={t("dashboard.plans.item_label_1")}
                handleChange={(value) => handlePlanChangeTitle(value, i)}
                inputValue={item.title}
                autoFocus={selectedInput === i.toString()}
                max={25}
              />

              <SimpleInput
                style={{ marginRight: "10px", maxWidth: "60px" }}
                id={"title"}
                name={t("dashboard.plans.item_label_2")}
                isDisabled={true}
                inputValue={
                  Number(item.generalLimit) > 0
                    ? item.generalLimit
                    : returnLargerLimit(
                      item.restaurantLimit,
                      item.supermarketLimit
                    )
                }
                handleChange={() => {
                  //
                }}
              />
              <SimpleInput
                style={{ minWidth: "160px", marginRight: "10px" }}
                id={"title"}
                name={t("dashboard.plans.item_label_3")}
                isDisabled={true}
                inputValue={transformInputDates(
                  item.dateRange.startDate,
                  item.dateRange.endDate
                )}
                handleChange={() => {
                  //
                }}
              />

              {!item.limit ? (
                <div className="selected_dates_container">
                  {daysOptions.map((day: any) => (
                    <div
                      className={
                        item.selectedDays &&
                        item.selectedDays.includes(day.value)
                          ? "selected_date_box"
                          : "selected_date_box unselected"
                      }
                      key={day.value.toString()}
                    >
                      {day.label.slice(0, 2)}
                    </div>
                  ))}
                </div>
              ) : null}

              {item.employee.length > 0 ? (
              // <Button
              //   style={{ fontSize: "12px", marginTop: "17px" }}
              //   onClick={() => openEmployeeModal(i)}
              //   className="add_employee teritary"

              // >
              //   {item.employee.length} {t("dashboard.plans.selected_btn")}
              // </Button>

                <Button
                  buttonStyles={{ fontSize: "12px", marginTop: "17px" }}
                  disabled={isCopyPlan && !fetchedCopyData}
                  buttonType="primary with_tooltip"
                  buttonHtmlType="submit"
                  fullWidth={true}
                  buttonLabel={
                    item.employee.length +
                    " " +
                    t("dashboard.plans.selected_btn")
                  }
                  buttonHandler={() => openEmployeeModal(i)}
                />
              ) : (
                <button
                  style={{ fontSize: "12px", marginTop: "17px" }}
                  onClick={() => openEmployeeModal(i)}
                  className="add_employee primary"
                >
                  <img src={employeeIcon} alt="addPlan" />
                  {t("dashboard.plans.select_employee_btn")}
                </button>
              )}

              {Object.keys(updatePlanData).length ? null : (
                <button
                  style={{ fontSize: "12px", marginTop: "17px" }}
                  onClick={() => removePlan(i)}
                  disabled={invoicesExists}
                  className="plan_delete_button"
                >
                  <img src={deletePlanIcon} alt="deletePlanIcon" />
                </button>
              )}
            </div>
          ))}
        </div>
      )}

      <Modal style={modalStyles} isOpen={showModal}>
        <EmployeePlansModal
          updatePlanData={updatePlanData}
          closeModal={handleModal}
          employeeInPlan={employeeInPlan}
          currentPlan={plansArray[currentPlan || 0]}
          currentPlanIndex={currentPlan || 0}
          plans={plansArray}
          isCopyPlan={isCopyPlan}
        />
      </Modal>
    </div>
  );
}

const mapStateToProps = (state: RootState) => {
  return {
    selectedRows: state.selectedRows.selectedRows,
    addedPlans: state.addedPlans.addedPlans,
    company: state.company.company.company
  };
};

export default connect(mapStateToProps, null)(LunchForm);
