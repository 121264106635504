import {Dispatch} from "redux";
import axios from "axios";
import environment from "../../environment";
import {
  CLEAR_PLAN_DATA,
  DEACTIVATE_PLAN_SUCCESS, GET_BILLING_PLANS_SUCCESS,
  GET_ONE_PLAN_SUCCESS, GET_PLAN_DATA_SUCCESS,
  GET_PLANS_SUCCESS,
  PLAN_CLEAR_MESSAGES,
  PLAN_ERROR,
  PLANS_LOADING,
  plansDispatchTypes,
  POST_PLANS_SUCCESS,
  UPDATE_PLAN_SUCCESS
} from "../types/plansTypes";
import {errorHandler} from "../../utils/errorHandler";
import {getToken} from "../../utils/cookies/tokensCookie";
import {IPlan} from "../../Interfaces/IPlans";
import customHistory from "../../customHistory";
import { reduxRequestHandler } from "../../services/reduxRequestHandler";
import {urls} from "../../api/urls";
import {getUserCompany} from "../../utils/cookies/userCookies/userCookies";

interface IGetPlansParams {
  limit?:number;
  skip?:number;
  sortBy?:string;
  sort?:string;
  search?:string;
  active?:string;
  [key: string]: any;
}

interface LooseObject {
  [key: string]: any
}


// Deactivate plan
export const deactivatePlan = (planId:string) =>
  async (dispatch: Dispatch<plansDispatchTypes>) => {
    const token = getToken();
    const fullUrl = `${environment.baseUrl}${urls.plans.plans}/${planId}`;
    const options = {headers: {"Authorization": `Bearer ${token}`}};
    const timeout = setTimeout(() => {
      customHistory.push("/server-error");
    }, 10000);
    try {
      await axios.delete(fullUrl, options);
      clearTimeout(timeout);
      dispatch({
        type: DEACTIVATE_PLAN_SUCCESS,
        payload: "deactivate_plan"
      });
    } catch (e: any) {
      clearTimeout(timeout);
      if (!errorHandler(e.request.status)) {
        dispatch({
          type: PLAN_ERROR,
          payload: "deactivate_plan"
        });
      }
    }
  };

// Get one plan
export const getOnePlan = (
  planId: string, limit?:number, skip?:number, sortBy?: string) =>
  async (dispatch: Dispatch<plansDispatchTypes>) => {
    const companyId = getUserCompany();
    const dynamicParams = {
      planId: planId || "",
      limit: limit || 8,
      skip: skip || 0,
      sortBy: sortBy || "firstName"
    };
    await new reduxRequestHandler({
      url: `${urls.invoices.calculationByUserByCompanyId}${companyId}`,
      dynamicParams,
      successDispatchType: GET_ONE_PLAN_SUCCESS,
      errorDispatchType: PLAN_ERROR,
      errorPayload: "get_one_plan"
    }).get(dispatch);
  };

// Get plan data
export const getPlanData = (planId: string) =>
  async (dispatch: Dispatch<plansDispatchTypes>) => {
    await new reduxRequestHandler({
      url: `${urls.plans.plans}/${planId}`,
      successDispatchType: GET_PLAN_DATA_SUCCESS,
      errorDispatchType: PLAN_ERROR,
      errorPayload: "get_plan_data"
    }).get(dispatch);
  };

// Get plans
export const plansData = (filters:IGetPlansParams) =>
  async (dispatch: Dispatch<plansDispatchTypes>) => {
    const dynamicParams = {
      limit: filters.limit || 0,
      skip: filters.skip || 0,
    };

    Object.keys(filters).map((key:any) => {
      if (filters[key] && filters[key].length) {
        Object.assign(dynamicParams, {[key]: filters[key]});
      }
    });

    const companyId = getUserCompany();
    await new reduxRequestHandler({
      url: `${urls.plans.plans}?companyId=${companyId}`,
      dynamicParams,
      successDispatchType: GET_PLANS_SUCCESS,
      errorDispatchType: PLAN_ERROR,
      errorPayload: "get_plans"
    }).get(dispatch);
  };

// Get billing plan
export const getBillingPlan = (filters:IGetPlansParams, planId: string) =>
  async (dispatch: Dispatch<plansDispatchTypes>) => {

    const dynamicParams = {
      limit: filters.limit || 0,
      skip: filters.skip || 0,
    };

    Object.keys(filters).map((key:any) => {
      if (filters[key] && filters[key].length) {
        Object.assign(dynamicParams, {[key]: filters[key]});
      }
    });

    await new reduxRequestHandler({
      url: `${urls.plans.billing}/${planId}`,
      dynamicParams,
      successDispatchType: GET_BILLING_PLANS_SUCCESS,
      errorDispatchType: PLAN_ERROR,
      errorPayload: "get_plans"
    }).get(dispatch);
  };

// Post new Plan
export const postPlansData = (data:IPlan) =>
  async (dispatch: Dispatch<plansDispatchTypes>) => {
    const token = getToken();
    const fullUrl = `${environment.baseUrl}${urls.plans.multiplePlans}`;
    const options = {headers: {"Authorization": `Bearer ${token}`}};
    const timeout = setTimeout(() => {
      customHistory.push("/server-error");
    }, 10000);
    try {
      await axios.post(fullUrl , data, options);
      clearTimeout(timeout);
      dispatch({
        type: POST_PLANS_SUCCESS,
        payload: "post_plans"
      });
    } catch (e: any) {
      clearTimeout(timeout);
      if (!errorHandler(e.request.status)) {
        dispatch({
          type: PLAN_ERROR,
          payload: "post_plans"
        });
      }
    }
  };

// Update plan
export const updatePlan = (data:IPlan, planId:string) =>
  async (dispatch: Dispatch<plansDispatchTypes>) => {
    const token = getToken();
    const fullUrl = `${environment.baseUrl}${urls.plans.plans}/${planId}`;
    const options = {headers: {"Authorization": `Bearer ${token}`}};
    const timeout = setTimeout(() => {
      customHistory.push("/server-error");
    }, 10000);
    try {
      await axios.put(fullUrl , data, options);
      clearTimeout(timeout);
      dispatch({
        type: UPDATE_PLAN_SUCCESS,
        payload: "update_plan"
      });
    } catch (e: any) {
      clearTimeout(timeout);
      if (!errorHandler(e.request.status)) {
        dispatch({
          type: PLAN_ERROR,
          payload: "update_plan"
        });
      }
    }
  };

// Show loading
export function showPlansLoading() {
  return {
    type: PLANS_LOADING
  };
}

// Clear Messages
export function clearDeactivatePlanMessages() {
  return {
    type: PLAN_CLEAR_MESSAGES
  };
}

// Clear plan data
export function clearPlanData() {
  return {
    type: CLEAR_PLAN_DATA
  };
}

